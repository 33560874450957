export default function() {
  return [
    {
      component: 'ContactList',
      props: {
        showLogo: false,
      },
      data: {
        contacts: this.$path('item.roles.salesman'),
      },
    },
    {
      component: 'Lead',
      props: {
        options: {
          placeholders: {
            'contact.name': this.$t('name'),
            'contact.email': this.$t('email'),
            'contact.phone': this.$t('phone'),
          },
          fields: ['!title', '!upsell', '!comment'],
          actionLabel: 'Send til megler',
        },
      },
      data: {
        project: this.$path('item.id'),
        reference: { onModel: 'Project', ref: this.$path('item.id') },
      },
    },
  ]
}
