<template>
  <Loader style="flex-grow: 1">
    <div class="contact-page">
      <h2 class="contact-page__title" v-html="customContent.heading"></h2>
      <div class="contact-page__content">
        <Blocks :blocks="config"></Blocks>
      </div>
      <img class="contact-page__logo" :src="$path('broker-logo.url', customContent)" />
    </div>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { GetCustomField } from '@/utils'

import Roles from '@/components/Project/Roles'
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  computed: {
    ...mapState('Project', ['item']),
    config() {
      return config.call(this)
    },

    customContent() {
      if (!this.item) return
      return GetCustomField('lead-info', this.item.customFields) || []
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: 'Meld interesse',
    }
  },
  components: {
    Roles,
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  padding-block: 10rem;
  line-height: 1.2;
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  .elder-input {
    &__element {
      padding: 15px 15px;
      @include respond-below('tablet') {
        padding: 1rem;
      }
    }
  }

  .elder-button {
    background: $beige;
    color: var(--primary);
    border-radius: 0;
    border: 2px solid $beige;
  }

  .elder-button:hover {
    background: var(--primary);
    color: $beige;
    border-radius: 0;
  }

  @keyframes Leknp {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__title {
    text-align: center;
    font-size: 2.15rem;
    margin: 0rem;
    padding: 0rem;
    font-weight: 400;
    color: inherit;
    p {
      font-size: 1rem;
    }

    @include respond-below('phone') {
      padding: 0;

      font-size: 1.4rem;
    }

    opacity: 0;
    animation: Leknp 2s 0.4s forwards;
  }
  &__paragraph {
    text-align: center;
    max-width: 520px;
  }

  &__content {
    display: grid;
    justify-content: center;
    grid-template-columns: 38% 62%;

    @include respond-below('tablet') {
      padding-bottom: 4rem;
      grid-template-columns: 1fr;
    }

    .roles {
      opacity: 0;
      animation: Leknp 2s 0.4s forwards;
    }

    .lead {
      &__form {
        background-color: var(--primary);
        .select-checklist__label {
          line-height: 1.5;
        }
        .elder-input__label {
          display: none;
        }
      }
      margin-inline: initial;
      padding: 2rem;

      .elder-checkbox--checked .elder-checkbox__box {
        background-color: var(--secondary);
        path {
          fill: var(--primary);
        }
      }

      &__form-actions {
        opacity: 0;
        animation: Leknp 2s 0.4s forwards;
        justify-content: left;
        margin-top: 0;
        height: 20px;
      }
    }
  }

  &__logo {
    max-width: 200px;
    margin-top: 2rem;
    margin-left: 4rem;
  }

  @include respond-below('phone') {
    &__logo {
      margin-left: 0;
    }
  }

  .kpb-contact-list {
    opacity: 0;
    animation: Leknp 2s 0.4s forwards;
    padding: 2rem;
    margin-right: 5rem;

    @include respond-below('phone') {
      padding: 1rem;
      margin-right: 0;
    }

    &__avatar {
      width: 250px;

      background-size: cover;
      background-position: center;

      height: 440px;
      max-width: 250px;
      max-height: 250px;
      border-radius: 50%;
    }

    &__item {
      align-content: flex-end;
      flex-flow: column wrap;

      text-align: center;

      @include respond-below('phone') {
        align-content: center;
      }
    }

    &__name {
      font-size: 1rem !important;
      font-weight: 700 !important;
    }
    &__contact-info {
      .kpb-contact-list__email,
      .kpb-contact-list__phone {
        display: block;
        text-decoration: underline;
      }
    }

    &__email {
      text-decoration: underline;
    }
  }
}
</style>
